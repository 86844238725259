<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-1">
      Detail Assign Partner
    </div>
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <validation-observer ref="formRules"
        v-slot="{ invalid }">
        <b-row>
          <b-col md="8">
          <!-- form -->
            <b-form>
              <b-row>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      No SK <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="No SK"
                      :rules="'required'"
                    >
                      <b-form-input
                        v-model="sk"
                        placeholder="Contoh: SK-1291/KM/2023"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      URL <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="URL"
                      :rules="{ regex: regexUrl, required: true }"
                    >
                      <b-form-input
                        v-model="url"
                        placeholder="Ketikkan URL"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Tanggal Mulai <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Tanggal Mulai"
                      :rules="'required'"
                    >
                      <flat-pickr
                        v-model="startDate"
                        class="form-control"
                        :config="{
                          altInput: true,
                          altFormat: 'j F Y',
                          dateFormat: 'Y-m-d',
                        }"
                        placeholder="Pilih Tanggal"
                      />
                      <small v-if="!errors[0]">*Tanggal talent mulai dihire oleh partner</small>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
        <section>
          <div class="d-flex align-items-center mb-[20px]">
            <button
              class="ml-auto px-1 button-primary py-[8px]"
              @click="showAddTalent = true"
            >
              <strong>+ Kolom</strong>
            </button>
          </div>
          <BTable
            :items="talentAssignments"
            :fields="fields"
            empty-text="Tidak ada data yang ditampilkan."
            responsive
            show-empty
          >
            <template #cell(id)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(action)="data">
              <div class="flex justify-end items-center">
                <BButton class="btn-icon mr-50" size="sm" variant="flat-warning"
                  @click="deleteTalent(data.index)"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/trash.svg"
                    alt="Komerce"
                  />
                </BButton>
              </div>
            </template>
          </BTable>
        </section>
        <div class="d-flex justify-content-end mt-3">
          <button
            type="kembali"
            class="mr-50 button-secondary"
            v-b-modal.modalQuit
            @click="talentAssignments.length ? $bvModal.show('modalQuit') : $router.back()"
          >
            Kembali
          </button>
          <button
            class="mr-50 button-primary w-[200px]"
            :disabled="invalid || loadingSubmit || !talentAssignments.length"
            @click.prevent="submit()"
          >
            <b-spinner
              v-if="loadingSubmit"
              small
            />
            Assign
          </button>
        </div>
      </validation-observer>
    </b-overlay>
    <ModalQuit/>
    <ModalConfirmation/>
    <ModalAdd
      v-if="showAddTalent"
      @addTalent="handleAdd"
      @close="showAddTalent = false"
      :talentExist="talentExist"
      :deviceExist="deviceExist"
    />
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm,
  BCol,
  BOverlay,
} from 'bootstrap-vue'
import {
  required,
  min,
  minValue,
  max,
  alpha_spaces,
  numeric,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.id'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { isAlphabet, capitalize, regexUrl } from '@/libs/helpers'
import { tableAssignment } from './config'
import ModalAdd from './ModalAdd.vue'
import ModalQuit from './ModalQuit.vue'
import ModalConfirmation from './ModalConfirmation.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BCol,
    BOverlay,
    flatPickr,
    ModalConfirmation,
    ModalQuit,
    ModalAdd,
  },
  data() {
    return {
      loading: false,
      loadingSubmit: false,
      submitErrors: '',
      capitalize,
      regexUrl,
      showAddTalent: false,

      required,
      min,
      max,
      minValue,
      alpha_spaces,
      numeric,

      sk: '',
      url: '',
      startDate: null,

      fields: tableAssignment,
      talentAssignments: [],

      talentExist: [],
      deviceExist: [],

      alertError,
      alertSuccess,
    }
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const data = {
        sk_number: this.sk,
        url_document: this.url,
        start_date: this.startDate,
        talent_assignments: this.talentAssignments,
      }

      const url = 'v1/partner_assignments/store'
      await komtimAxiosIns
        .post(url, data)
        .then(() => {
          const text = 'Berhasil menambahkan data'
          this.alertSuccess(text)

          this.$router.back()
        })
        .catch(error => {
          this.alertError(error)
        })
    },
    handleAdd(payload) {
      this.talentAssignments.push(payload)
      this.talentExist.push(payload.talent_id)
      this.deviceExist.push(payload.device_id)
    },
    deleteTalent(index) {
      this.talentAssignments.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
